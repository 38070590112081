.container{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
    background-image: linear-gradient(188deg, rgba(78,192,179,1) 0%, rgba(66,187,140,1) 86%);
}

.icons{
    color: white;
}